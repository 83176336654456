import React from 'react';
import { Link } from 'react-router-dom';

import Navbar from '../components/global-components/Navbar2';
import PageHeader from '../components/global-components/PageHeader';
import AboutV4 from '../components/section-components/about-v5';
import Features from '../components/section-components/features-v1';
import Team from '../components/section-components/team-v1';
import Testimonial from '../components/section-components/Testimonials';
import BlogSlider from '../components/blog-components/blog-slider-v1';
import Footer from '../components/global-components/Footer';
import Newsletter from '../components/global-components/Newsletter';
import Services from '../components/section-components/Services';
import Main from '../components/global-components/Main';

/**
 * 
 * @returns 
 */
const AlfaImmo = () => {

    const teams = [
        {
            img: 'assets/img/illustrations/Alpha-Seck.png',
            fullname: 'Alpha Seck',
            role: 'Fondateur de Alfa Corporation',
            socialsnetwork:{
                facebook: '',
                twitter: '',
                linkedin: ''
            }
        },
        {
            // img: 'assets/img/team/4.jpg',
            img: 'assets/img/illustrations/Deboye-Niang.png',
            fullname: 'Déboye Niang',
            role: 'Fondateur et PDG du cabinet Carrée',
            socialsnetwork: {
                facebook: '',
                twitter: '',
                linkedin: ''
            }
        }
    ];
    const publicUrl = process.env.PUBLIC_URL + '/';

    return (
        <>
            <Navbar />
            <Main>
                <div className='wrapper'>
                    <PageHeader
                        headertitle="À propos de ALFAIMMO"
                        customclass="mb-5"
                        breadcrumbs={['alfaimmo']}
                    />
                    {/* <AboutV4 /> */}
                    <div className="ltn__about-us-area personalized go-top pb-90 position-relative"
                        // style={{
                        //     backgroundImage: `url(${publicUrl}assets/img/illustrations/screen_2x.webp)`,
                        //     backgroundSize: 'cover',
                        //     backgroundPosition: 'center center'
                        // }}
                    >
                        {/* <div className='position-absolute top-0 left-0 right-0 bottom-0 w-100 h-100 bg-overlay-theme-black-80'></div> */}
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="section-title-area ltn__section-title-2--- text-center">
                                        <h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">Qui sommes-nous ?</h6>
                                        <h2 className="section-title">Le principal marché de vente et de location de biens immobiliers</h2>
                                    </div>
                                </div>
                                <div className="col-lg-12 align-self-center">
                                    <div className="about-us-img-wrap- ltn__img-shape-left-- ltn__tertiary-color--- about-img-left-">
                                        {/* <img
                                            src={`${publicUrl}assets/img/illustrations/partenariat.jpg`}
                                            className='d-lg-none'
                                            alt="Illustration"
                                            />
                                        <img
                                            src={`${publicUrl}assets/img/illustrations/partenariat.jpg`}
                                            alt="Illustration"
                                            className='d-none d-lg-inline-block'
                                        /> */}
                                        {/* <img
                                            src={`${publicUrl}assets/img/illustrations/partenariat.jpg`}
                                            alt="Illustration"
                                            className='d-none d-lg-inline-block'
                                        /> */}
                                    </div>
                                </div>
                                <div className="col-lg-12 align-self-center">
                                    <div className="about-us-info-wrap">
                                        {/* <div className="section-title-area ltn__section-title-2--- mb-20">
                                            <h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">Qui sommes-nous ?</h6>
                                            <h2 className="section-title">Le principal marché de vente et de location de biens immobiliers</h2>
                                            <p>Notre partenariat est né d’une collaboration stratégique autour d’un projet d’affaires réussi, dénommé Cité Maniang Seck.</p>
                                        </div> */}
                                        <div className="about-us-info-wrap-inner about-us-info-devide---">
                                            <p>Notre partenariat est né d’une collaboration stratégique autour d’un projet d’affaires réussi, dénommé Cité Maniang Seck.</p>
                                            <p className='mb-0'>À travers cette plateforme, notre mission est d’offrir aux Sénégalais, qu’ils résident au pays ou dans la diaspora, une offre foncière et immobilière accessible, qualitative et adaptée à tous.</p>
                                            <p className=''>Notre vision est de nous positionner comme le leader numéro un dans la promotion de biens immobiliers et fonciers dans le digital. Pour cela, nous proposons un parcours d’acquisition clair, transparent et simplifié, grâce à une plateforme innovante et à un réseau de partenaires experts.</p>
                                            <p>Notre écosystème intègre des professionnels de confiance : notaires, géomètres, géologues, terrassiers, constructeurs et promoteurs immobiliers. Tous sont mobilisés pour répondre aux moindres besoins de nos clients, garantissant une expérience fluide et sécurisée à chaque étape.</p>
                                        </div>
                                        <div className="about-us-info-wrap-inner about-us-info-devide---">
                                        </div>
                                        {/* <div className="btn-wrapper animated">
                                            <Link to="/about" className="theme-btn-1 btn btn-effect-1 text-uppercase">About Us</Link>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Services />
                    {/* <Features customClass="ltn__feature-area section-bg-1 pt-120 pb-90 mb-120---" /> */}
                    {/* <Team /> */}
                    <div className="ltn__team-area pt-115 pb-90 go-top">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="section-title-area ltn__section-title-2--- text-center">
                                        <h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">Notre équipe</h6>
                                        <h2 className="section-title">Management team</h2>
                                    </div>
                                </div>
                            </div>
                            <div className="row justify-content-center">
                            {
                                teams.map((m, index) => {
                                    return (
                                        <div
                                            className="col-lg-4 col-sm-6"
                                            key={index}
                                        >
                                            <div className="ltn__team-item ltn__team-item-3---">
                                                <div className="team-img">
                                                    <img
                                                        src={`${publicUrl}${m.img}`}
                                                        alt=""
                                                    />
                                                </div>
                                                <div className="team-info">
                                                    <h4>
                                                        <Link to="/team-details">{m.fullname}</Link>
                                                    </h4>
                                                    <h6 className="ltn__secondary-color">{m.role}</h6>
                                                    <div className="ltn__social-media">
                                                        <ul>
                                                            <li>
                                                                <a href={m.socialsnetwork.facebook}>
                                                                    <i className="fab fa-facebook-f" />
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href={m.socialsnetwork.twitter}>
                                                                    <i className="fab fa-twitter" />
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href={m.socialsnetwork.linkedin}>
                                                                    <i className="fab fa-linkedin" />
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })
                            }
                            </div>
                        </div>
                    </div>
                    <Testimonial />
                    {/* <BlogSlider /> */}
                    <Newsletter />
                </div>
            </Main>
            <Footer />
        </>
    );
};

export default AlfaImmo;
